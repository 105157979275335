import firebase from "../shared/firebase";

const db = firebase.firestore();

class AuthService {
  // if there is a uid in local storage, we get user
  handleAuthentication() {
    const uid = this.getUID();
    if (!uid) {
      return;
    }

    this.getUser(uid);
  }

  // log in with email and password
  loginWithEmailPassword = async (email, password) => {
    let storeData = null;
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (result) => {
        // fetch user data
        const store = await this.getUser(result.user.uid);
        storeData = store;
      })
      .catch((err) => {
        throw err;
      });
    return storeData;
  };

  // get user from users collection
  getUser = async (uid) => {
    let setStore = {
      user: null,
      org: null,
    };

    let orgPromiseArray = [];
    let orgOptions = [];

    await db
      .collection("users")
      .doc(uid)
      .get()
      .then(async (queriedUser) => {
        setStore.user = {
          ...queriedUser.data(),
          uid: uid,
        };
        // iterate over orgs that user belongs to
        if (setStore.user.orgs) {
          // add each query for org to promise array
          setStore.user.orgs.forEach((orgId) => {
            orgPromiseArray.push(this.getOrg(orgId));
          });

          // get all orgs that user belongs to
          await Promise.all(orgPromiseArray).then((results) => {
            orgOptions = [...results];
          });

          // set org options in redux
          setStore.user.orgOptions = orgOptions;

          // check if user has favorite org
          let favoriteOrg;

          const structureMap = setStore.user.orgStructures;

          for (let orgId of Object.keys(structureMap)) {
            if (structureMap[orgId].favorite) {
              favoriteOrg = structureMap[orgId];
              favoriteOrg.id = orgId;
            }
          }

          if (favoriteOrg) {
            setStore.org = orgOptions.find((org) => org.id === favoriteOrg.id);
            setStore.user.favOrg = favoriteOrg;
          } else {
            setStore.org = orgOptions[0];
            setStore.user.favOrg = {};
          }
        }
        localStorage.setItem("coTrackUID", uid);
      })
      .catch((err) => {
        console.log("error getting user: ", err);
        throw err;
      });
    return setStore;
  };

  // get org from org collection
  getOrg = async (orgId) => {
    let returnOrg;
    await db
      .collection("orgs")
      .doc(orgId)
      .get()
      .then((org) => {
        returnOrg = org.data();
        returnOrg.id = orgId;
      })
      .catch((err) => {
        console.log("err: ", err);
        throw err;
      });
    return returnOrg;
  };

  // log user out
  logout = async () => {
    await firebase
      .auth()
      .signOut()
      .then((result) => {
        return result;
      })
      .catch((err) => {
        console.log("err: ", err);
        throw err;
      });
  };

  getUID = () => localStorage.getItem("coTrackUID");

  isAuthenticated = () => !!this.getUID();
}

const authService = new AuthService();

export default authService;
