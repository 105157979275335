import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import PhoneOne from "../../assets/images/PhoneOne.png";
import PhoneTwo from "../../assets/images/PhoneTwo.png";

export const MidSection = React.memo(() => {
  return (
    <div>
      <div className="container">
        <Box paddingTop={7}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Typography variant="h2" color="textPrimary" align="center">
                <Box fontWeight="fontWeightBold">Reduce Human Interaction</Box>
              </Typography>
            </Grid>
            <Grid container item justify="center">
              <Grid item xs={12}>
                <Typography variant="h4" color="textPrimary" align="center">
                  <Box>
                    Still asking your users Covid symptom questions in person?
                    Have them answer your questions prior to coming in.
                  </Box>
                </Typography>
              </Grid>
            </Grid>
            <Grid container item direction="row" justify="space-between">
              <Grid item xs={5}>
                <img alt="responses" src={PhoneOne} className="img-fluid"></img>
              </Grid>
              <Grid item xs={5}>
                <img alt="answered" src={PhoneTwo} className="img-fluid"></img>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
});
