import React from "react";
import { Grid, Hidden, Typography, Box } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import CoTracking from "../../assets/images/CoTrackingTransparent.png";

export const NavLeft = React.memo(() => {
  return (
    <Grid container direction="row" spacing={3} alignItems="center">
      <Grid item>
        <NavLink exact to="/home">
          <img alt="home" src={CoTracking} height="20px" width="auto"></img>
        </NavLink>
      </Grid>
      <Hidden mdDown>
        <Grid item>
          <NavLink
            exact
            to="/pricing"
            style={{
              textDecoration: "none",
              color: "#17252A",
            }}
            activeStyle={{
              textDecoration: "none",
              color: "#3AAFA9",
            }}
          >
            <Box paddingTop={1}>
              <Typography>Free Trial</Typography>
            </Box>
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            exact
            to="/contact"
            style={{
              textDecoration: "none",
              color: "#17252A",
            }}
            activeStyle={{
              textDecoration: "none",
              color: "#3AAFA9",
            }}
          >
            <Box paddingTop={1}>
              <Typography>Contact Us</Typography>
            </Box>
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            exact
            to="/faqs"
            style={{
              textDecoration: "none",
              color: "#17252A",
            }}
            activeStyle={{
              textDecoration: "none",
              color: "#3AAFA9",
            }}
          >
            <Box paddingTop={1}>
              <Typography>FAQs</Typography>
            </Box>
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            exact
            to="/demos"
            style={{
              textDecoration: "none",
              color: "#17252A",
            }}
            activeStyle={{
              textDecoration: "none",
              color: "#3AAFA9",
            }}
          >
            <Box paddingTop={1}>
              <Typography>Demos</Typography>
            </Box>
          </NavLink>
        </Grid>
      </Hidden>
    </Grid>
  );
});
