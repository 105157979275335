import React, { lazy, Suspense, Fragment } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "./store/actions";

import Home from "./components/Home/Home";
import LoadingScreen from "./components/LoadingScreen";
import DashboardLayout from "./DashboardLayout";

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

const getCurrentOrg = (structures, orgId) => {
  let org = structures[orgId];
  org.id = orgId;
  return org;
};

export const Routes = () => {
  const user = useSelector((state) => state.auth.user);
  const selectedOrg = useSelector((state) => state.auth.org);
  const authenticated = user.uid ? true : false;
  const dispatch = useDispatch();

  // listen for online status changing
  window.addEventListener("online", () => {
    dispatch(actionTypes.updateNetworkStatus(true));
  });

  // now we listen for network status changes
  window.addEventListener("offline", () => {
    dispatch(actionTypes.updateNetworkStatus(false));
  });

  const getRoutes = () => {
    let routes = null;

    if (!authenticated) {
      // create routes config
      routes = [
        {
          exact: true,
          path: "/",
          component: () => <Redirect to="/home" />,
        },
        {
          exact: true,
          path: "/login",
          component: lazy(() => import("../src/components/Auth/Auth")),
        },
        {
          exact: true,
          path: "/pricing",
          component: lazy(() => import("../src/components/Pricing/")),
        },
        {
          exact: true,
          path: "/getstarted",
          component: lazy(() => import("../src/components/Started/")),
        },
        {
          exact: true,
          path: "/join/:orgId",
          component: lazy(() => import("./views/JoinOrgView")),
        },
        {
          exact: true,
          path: "/respond/:orgId",
          component: lazy(() => import("./views/ExternalResponseView")),
        },
        {
          exact: true,
          path: "/contact",
          component: lazy(() => import("../src/components/Contact/")),
        },
        {
          exact: true,
          path: "/faqs",
          component: lazy(() => import("../src/components/FAQ/")),
        },
        {
          exact: true,
          path: "/demos",
          component: lazy(() => import("../src/components/Demos/")),
        },
        {
          exact: true,
          path: "/404",
          component: lazy(() => import("../src/views/Error404View")),
        },
        {
          path: "*",
          routes: [
            {
              exact: true,
              path: "/home",
              component: Home,
            },
            {
              component: () => <Redirect to="/404" />,
            },
          ],
        },
      ];
    } else {
      // user is not part of any org
      if (!("orgs" in user) || user.orgs.length === 0) {
        routes = [
          {
            exact: true,
            path: "/",
            component: () => <Redirect to="/dash" />,
          },
          {
            exact: true,
            path: "/login",
            component: lazy(() => import("../src/components/Auth/Auth")),
          },
          {
            exact: true,
            path: "/pricing",
            component: lazy(() => import("../src/components/Pricing/")),
          },
          {
            exact: true,
            path: "/getstarted",
            component: lazy(() => import("../src/components/Started/")),
          },
          {
            exact: true,
            path: "/join/:orgId",
            component: lazy(() => import("./views/JoinOrgView")),
          },
          {
            exact: true,
            path: "/respond/:orgId",
            component: lazy(() => import("./views/ExternalResponseView")),
          },
          {
            exact: true,
            path: "/contact",
            component: lazy(() => import("../src/components/Contact/")),
          },
          {
            exact: true,
            path: "/faqs",
            component: lazy(() => import("../src/components/FAQ/")),
          },
          {
            exact: true,
            path: "/demos",
            component: lazy(() => import("../src/components/Demos/")),
          },
          {
            exact: true,
            path: "/404",
            component: lazy(() => import("../src/views/Error404View")),
          },
          {
            path: "/dash",
            // guard: AuthGuard,
            layout: DashboardLayout,
            routes: [
              {
                exact: true,
                path: "/dash",
                component: () => <Redirect to="/dash/addorg" />,
              },
              {
                exact: true,
                path: "/dash/addorg",
                component: lazy(() => import("./views/AddOrgView")),
              },
              {
                exact: true,
                path: "/dash/account",
                component: lazy(() => import("./views/AccountView")),
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: "*",
            routes: [
              {
                exact: true,
                path: "/home",
                component: Home,
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
        ];
      } else {
        const currentOrg = getCurrentOrg(user.orgStructures, selectedOrg.id);

        // owner routes
        const ownerRoutes = [
          {
            exact: true,
            path: "/",
            component: () => <Redirect to="/dash" />,
          },
          {
            exact: true,
            path: "/login",
            component: lazy(() => import("../src/components/Auth/Auth")),
          },
          {
            exact: true,
            path: "/pricing",
            component: lazy(() => import("../src/components/Pricing/")),
          },
          {
            exact: true,
            path: "/getstarted",
            component: lazy(() => import("../src/components/Started/")),
          },
          {
            exact: true,
            path: "/join/:orgId",
            component: lazy(() => import("./views/JoinOrgView")),
          },
          {
            exact: true,
            path: "/respond/:orgId",
            component: lazy(() => import("./views/ExternalResponseView")),
          },
          {
            exact: true,
            path: "/contact",
            component: lazy(() => import("../src/components/Contact/")),
          },
          {
            exact: true,
            path: "/faqs",
            component: lazy(() => import("../src/components/FAQ/")),
          },
          {
            exact: true,
            path: "/demos",
            component: lazy(() => import("../src/components/Demos/")),
          },
          {
            exact: true,
            path: "/404",
            component: lazy(() => import("../src/views/Error404View")),
          },
          {
            path: "/dash",
            // guard: AuthGuard,
            layout: DashboardLayout,
            routes: [
              {
                exact: true,
                path: "/dash",
                component: () => <Redirect to="/dash/responses" />,
              },
              {
                exact: true,
                path: "/dash/questions",
                component: lazy(() => import("./views/CreationView")),
              },
              {
                exact: true,
                path: "/dash/answer",
                component: lazy(() => import("./views/AnswerView")),
              },
              {
                exact: true,
                path: "/dash/responses",
                component: lazy(() => import("./views/ResponsesView")),
              },
              {
                exact: true,
                path: "/dash/external",
                component: lazy(() => import("./views/ExternalView")),
              },
              {
                exact: true,
                path: "/dash/details",
                component: lazy(() => import("./views/OrgDetailView")),
              },
              {
                exact: true,
                path: "/dash/subscription",
                component: lazy(() => import("./views/SubscriptionView")),
              },
              {
                exact: true,
                path: "/dash/users",
                component: lazy(() => import("./views/ManageUsersView")),
              },
              {
                exact: true,
                path: "/dash/account",
                component: lazy(() => import("./views/AccountView")),
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: "*",
            routes: [
              {
                exact: true,
                path: "/home",
                component: Home,
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
        ];

        // admin routes
        const adminRoutes = [
          {
            exact: true,
            path: "/",
            component: () => <Redirect to="/dash" />,
          },
          {
            exact: true,
            path: "/login",
            component: lazy(() => import("../src/components/Auth/Auth")),
          },
          {
            exact: true,
            path: "/pricing",
            component: lazy(() => import("../src/components/Pricing/")),
          },
          {
            exact: true,
            path: "/getstarted",
            component: lazy(() => import("../src/components/Started/")),
          },
          {
            exact: true,
            path: "/join/:orgId",
            component: lazy(() => import("./views/JoinOrgView")),
          },
          {
            exact: true,
            path: "/respond/:orgId",
            component: lazy(() => import("./views/ExternalResponseView")),
          },
          {
            exact: true,
            path: "/contact",
            component: lazy(() => import("../src/components/Contact/")),
          },
          {
            exact: true,
            path: "/faqs",
            component: lazy(() => import("../src/components/FAQ/")),
          },
          {
            exact: true,
            path: "/demos",
            component: lazy(() => import("../src/components/Demos/")),
          },
          {
            exact: true,
            path: "/404",
            component: lazy(() => import("../src/views/Error404View")),
          },
          {
            path: "/dash",
            // guard: AuthGuard,
            layout: DashboardLayout,
            routes: [
              {
                exact: true,
                path: "/dash",
                component: () => <Redirect to="/dash/responses" />,
              },
              {
                exact: true,
                path: "/dash/answer",
                component: lazy(() => import("./views/AnswerView")),
              },
              {
                exact: true,
                path: "/dash/responses",
                component: lazy(() => import("./views/ResponsesView")),
              },
              {
                exact: true,
                path: "/dash/external",
                component: lazy(() => import("./views/ExternalView")),
              },
              {
                exact: true,
                path: "/dash/account",
                component: lazy(() => import("./views/AccountView")),
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: "*",
            routes: [
              {
                exact: true,
                path: "/home",
                component: Home,
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
        ];

        // mid routes
        const midRoutes = [
          {
            exact: true,
            path: "/",
            component: () => <Redirect to="/dash" />,
          },
          {
            exact: true,
            path: "/login",
            component: lazy(() => import("../src/components/Auth/Auth")),
          },
          {
            exact: true,
            path: "/pricing",
            component: lazy(() => import("../src/components/Pricing/")),
          },
          {
            exact: true,
            path: "/getstarted",
            component: lazy(() => import("../src/components/Started/")),
          },
          {
            exact: true,
            path: "/join/:orgId",
            component: lazy(() => import("./views/JoinOrgView")),
          },
          {
            exact: true,
            path: "/respond/:orgId",
            component: lazy(() => import("./views/ExternalResponseView")),
          },
          {
            exact: true,
            path: "/contact",
            component: lazy(() => import("../src/components/Contact/")),
          },
          {
            exact: true,
            path: "/faqs",
            component: lazy(() => import("../src/components/FAQ/")),
          },
          {
            exact: true,
            path: "/demos",
            component: lazy(() => import("../src/components/Demos/")),
          },
          {
            exact: true,
            path: "/404",
            component: lazy(() => import("../src/views/Error404View")),
          },
          {
            path: "/dash",
            // guard: AuthGuard,
            layout: DashboardLayout,
            routes: [
              {
                exact: true,
                path: "/dash",
                component: () => <Redirect to="/dash/responses" />,
              },
              {
                exact: true,
                path: "/dash/answer",
                component: lazy(() => import("./views/AnswerView")),
              },
              {
                exact: true,
                path: "/dash/responses",
                component: lazy(() => import("./views/ResponsesView")),
              },
              {
                exact: true,
                path: "/dash/account",
                component: lazy(() => import("./views/AccountView")),
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: "*",
            routes: [
              {
                exact: true,
                path: "/home",
                component: Home,
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
        ];

        // staff routes
        const userRoutes = [
          {
            exact: true,
            path: "/",
            component: () => <Redirect to="/dash" />,
          },
          {
            exact: true,
            path: "/login",
            component: lazy(() => import("../src/components/Auth/Auth")),
          },
          {
            exact: true,
            path: "/pricing",
            component: lazy(() => import("../src/components/Pricing/")),
          },
          {
            exact: true,
            path: "/getstarted",
            component: lazy(() => import("../src/components/Started/")),
          },
          {
            exact: true,
            path: "/join/:orgId",
            component: lazy(() => import("./views/JoinOrgView")),
          },
          {
            exact: true,
            path: "/respond/:orgId",
            component: lazy(() => import("./views/ExternalResponseView")),
          },
          {
            exact: true,
            path: "/contact",
            component: lazy(() => import("../src/components/Contact/")),
          },
          {
            exact: true,
            path: "/faqs",
            component: lazy(() => import("../src/components/FAQ/")),
          },
          {
            exact: true,
            path: "/demos",
            component: lazy(() => import("../src/components/Demos/")),
          },
          {
            exact: true,
            path: "/404",
            component: lazy(() => import("../src/views/Error404View")),
          },
          {
            path: "/dash",
            // guard: AuthGuard,
            layout: DashboardLayout,
            routes: [
              {
                exact: true,
                path: "/dash",
                component: () => <Redirect to="/dash/answer" />,
              },
              {
                exact: true,
                path: "/dash/answer",
                component: lazy(() => import("./views/AnswerView")),
              },
              {
                exact: true,
                path: "/dash/account",
                component: lazy(() => import("./views/AccountView")),
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
          {
            path: "*",
            routes: [
              {
                exact: true,
                path: "/home",
                component: Home,
              },
              {
                component: () => <Redirect to="/404" />,
              },
            ],
          },
        ];

        // is user is owner of current org
        if (currentOrg.title === "Owner") {
          routes = ownerRoutes;
        }

        // if selected org is sport
        if (
          currentOrg.title !== "Owner" &&
          (currentOrg.type === "Single-Sport Athletics" ||
            currentOrg.type === "Multi-Sport Athletics")
        ) {
          switch (currentOrg.title) {
            case "Admin":
              routes = adminRoutes;
              break;
            case "Coach":
              routes = midRoutes;
              break;
            case "Player":
              routes = userRoutes;
              break;
            default:
              break;
          }
        }

        // if selected org is healthcare
        if (currentOrg.title !== "Owner" && currentOrg.type === "Healthcare") {
          switch (currentOrg.title) {
            case "Admin":
              routes = adminRoutes;
              break;
            case "Department Head":
              routes = midRoutes;
              break;
            case "Supervisor":
              routes = midRoutes;
              break;
            case "Staff":
              routes = userRoutes;
              break;
            default:
              break;
          }
        }

        // if selected org is other
        if (currentOrg.title !== "Owner" && currentOrg.type === "Other") {
          switch (currentOrg.permission) {
            case "Admin":
              routes = adminRoutes;
              break;
            case "Manager":
              routes = midRoutes;
              break;
            case "Staff":
              routes = userRoutes;
              break;
            default:
              break;
          }
        }
      }
    }

    return routes;
  };
  return renderRoutes(getRoutes());
};
