import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import * as actionTypes from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Grid,
  IconButton,
  Button,
  Hidden,
  Drawer,
  List,
  Divider,
  ListItem,
} from "@material-ui/core";
import {
  Facebook,
  Instagram,
  Twitter,
  MenuSharp,
  CloseSharp,
} from "@material-ui/icons";

const DrawerList = React.memo(({ loggedIn, logout }) => {
  return (
    <List>
      <ListItem button>
        <NavLink
          exact
          to="/pricing"
          style={{
            textDecoration: "none",
            color: "#17252A",
          }}
          activeStyle={{
            textDecoration: "none",
            color: "#3AAFA9",
          }}
        >
          Free Trial
        </NavLink>
      </ListItem>
      <ListItem button>
        <NavLink
          exact
          to="/contact"
          style={{
            textDecoration: "none",
            color: "#17252A",
          }}
          activeStyle={{
            textDecoration: "none",
            color: "#3AAFA9",
          }}
        >
          Contact Us
        </NavLink>
      </ListItem>
      <ListItem button>
        <NavLink
          exact
          to="/faqs"
          style={{
            textDecoration: "none",
            color: "#17252A",
          }}
          activeStyle={{
            textDecoration: "none",
            color: "#3AAFA9",
          }}
        >
          FAQs
        </NavLink>
      </ListItem>
      <ListItem button>
        <NavLink
          exact
          to="/demos"
          style={{
            textDecoration: "none",
            color: "#17252A",
          }}
          activeStyle={{
            textDecoration: "none",
            color: "#3AAFA9",
          }}
        >
          Demos
        </NavLink>
      </ListItem>
      <Divider />
      <ListItem>
        {loggedIn ? (
          <NavLink
            exact
            to="/dash"
            style={{
              textDecoration: "none",
              color: "#17252A",
            }}
            activeStyle={{
              textDecoration: "none",
              color: "#3AAFA9",
            }}
          >
            Dashboard
          </NavLink>
        ) : (
          <NavLink
            exact
            to="/login"
            style={{
              textDecoration: "none",
              color: "#17252A",
            }}
            activeStyle={{
              textDecoration: "none",
              color: "#3AAFA9",
            }}
          >
            Login
          </NavLink>
        )}
      </ListItem>
    </List>
  );
});

export const NavRight = React.memo(() => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const loggedIn = user.uid !== null && user.uid !== undefined;

  const handleLogout = async () => {
    await dispatch(actionTypes.signOut());
    history.push("/login");
  };

  const toggleOpen = () => {
    setOpen((oldOpen) => {
      return !oldOpen;
    });
  };

  return (
    <Grid container direction="row" spacing={3} alignItems="center">
      <Hidden mdDown>
        <Grid
          container
          item
          direction="row"
          justify="center"
          alignItems="center"
          xs={8}
        >
          <Grid item>
            <a
              href="https://www.facebook.com/cotrackingapp"
              rel="noopener noreferrer"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "#17252A",
              }}
            >
              <IconButton>
                <Facebook fontSize="small" color="primary" />
              </IconButton>
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://twitter.com/cotrackingapp"
              rel="noopener noreferrer"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "#17252A",
              }}
            >
              <IconButton>
                <Twitter fontSize="small" color="primary" />
              </IconButton>
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://www.instagram.com/cotrackingapp/"
              rel="noopener noreferrer"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "#17252A",
              }}
            >
              <IconButton>
                <Instagram fontSize="small" color="primary" />
              </IconButton>
            </a>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          {loggedIn ? (
            <NavLink to="/dash" style={{ textDecoration: "none" }}>
              <Button variant="contained" color="secondary">
                Dashboard
              </Button>
            </NavLink>
          ) : (
            <NavLink to="/login" style={{ textDecoration: "none" }}>
              <Button variant="contained" color="primary">
                Login
              </Button>
            </NavLink>
          )}
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <IconButton onClick={() => toggleOpen()}>
          {open ? <CloseSharp /> : <MenuSharp />}
        </IconButton>
        <Drawer anchor="top" open={open} onClose={() => setOpen(false)}>
          <DrawerList loggedIn={loggedIn} logout={() => handleLogout()} />
        </Drawer>
      </Hidden>
    </Grid>
  );
});
