import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  FormHelperText,
  TextField,
  Box,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import states from "../shared/states.json";
import cities from "../shared/cities.json";
import firebase from "../shared/firebase";
import { Multi } from "./Auth/Structure";
import { Healthcare } from "./Auth/Structure";
import { Other } from "./Auth/Structure";

const db = firebase.firestore();

const useStyles = makeStyles((theme) => ({
  formControl: {
    paddingTop: theme.spacing(1),
    minWidth: 120,
  },
}));

export const SelectOrg = React.memo(
  ({ create, creating, org, orgArray, isOnline }) => {
    const classes = useStyles();
    const [orgOptions, setOrgOptions] = useState([]);
    const [loadingOrgs, setLoadingOrgs] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState(org ? org : null);
    const [searchData, setSearchData] = useState({
      state: "",
      city: "",
    });

    const [roleInfo, setRoleInfo] = useState({
      title: "",
      permission: "",
    });

    const [roleText, setRoleText] = useState("");

    const [seasons, setSeasons] = useState(null);
    const [shift, setShift] = useState("");
    const [department, setDepartment] = useState("");

    const handleStateCityChange = (type, value) => {
      setSearchData((old) => {
        let update = { ...old };
        update[type] = value;
        return update;
      });
    };

    const handleRoleChange = (event) => {
      // clear all values when role changes
      setSeasons(null);
      setShift("");
      setDepartment("");

      let newRole = event.target.value;
      setRoleText(newRole);

      let findRole = selectedOrg.roles.find((role) => role.title === newRole);
      setRoleInfo(findRole);
    };

    const handleSaveSeasons = (seasons) => {
      setSeasons(seasons);
    };

    const handleSaveHealthcare = (struct) => {
      handleSaveShift(struct.shift);
      handleSaveDepartment(struct.department);
    };

    const handleSaveShift = (shift) => {
      setShift(shift);
    };

    const handleSaveDepartment = (department) => {
      setDepartment(department);
    };

    const handleSelectOrg = (value) => {
      // reset role data
      setRoleInfo({
        title: "",
        permission: "",
      });
      setRoleText("");

      setSelectedOrg(value);
    };

    useEffect(() => {
      setLoadingOrgs(true);

      if (searchData.city && searchData.state) {
        db.collection("orgs")
          .where("state", "==", searchData.state)
          .where("city", "==", searchData.city)
          .where("status", "==", "active")
          .get()
          .then((snapshot) => {
            let orgs = [];
            if (!snapshot.empty) {
              snapshot.forEach((org) => {
                if (orgArray) {
                  if (!orgArray.includes(org.id)) {
                    orgs.push({ id: org.id, ...org.data() });
                  }
                } else {
                  orgs.push({ id: org.id, ...org.data() });
                }
              });
            }
            setOrgOptions(orgs);
            setLoadingOrgs(false);
          })
          .catch((err) => {
            console.log("error getting orgs: ", err);
            setLoadingOrgs(false);
          });
      }
    }, [orgArray, searchData.state, searchData.city]);

    const handleSubmit = (event) => {
      event.preventDefault();
      let structure = {
        id: selectedOrg.id,
        permission: roleInfo.permission,
        title: roleInfo.title,
        type: selectedOrg.type,
        favorite: true,
      };

      // set permissions for sports
      if (
        structure.type === "Single-Sport Athletics" ||
        structure.type === "Multi-Sport Athletics"
      ) {
        switch (structure.permission) {
          case "Admin":
            structure.status = "Pending";
            break;
          case "Coach":
            structure.status = "Pending";
            break;
          case "Player":
            structure.status = "Active";
            break;
          default:
            break;
        }
      }

      if (
        structure.type === "Multi-Sport Athletics" &&
        structure.permission !== "Admin"
      ) {
        structure.seasons = seasons;
      }

      // if type is healthcare
      if (structure.type === "Healthcare") {
        switch (structure.permission) {
          case "Admin":
            structure.status = "Pending";
            break;
          case "Department Head":
            structure.department = department;
            structure.status = "Pending";
            break;
          case "Supervisor":
            structure.shift = shift;
            structure.status = "Pending";
            break;
          case "Staff":
            structure.department = department;
            structure.shift = shift;
            structure.status = "Active";
            break;
          default:
            break;
        }
      }

      // if type is other
      if (structure.type === "Other") {
        switch (structure.permission) {
          case "Admin":
            structure.status = "Pending";
            break;
          case "Manager":
            structure.department = department;
            structure.status = "Pending";
            break;
          case "Staff":
            structure.department = department;
            structure.status = "Active";
            break;
          default:
            break;
        }
      }

      create(selectedOrg, structure);
    };

    return (
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={3}>
          {!org && (
            <Grid item>
              <Typography variant="h6" align="center">
                Search for your organization by State and City.
              </Typography>
            </Grid>
          )}
          {!org && (
            <Grid
              container
              item
              direction="row"
              spacing={1}
              justify="space-between"
              alignItems="center"
            >
              <Grid container item xs={6} direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="caption">
                    <Box fontWeight="fontWeightBold">State</Box>
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    value={searchData.state}
                    fullWidth
                    select
                    onChange={(event) =>
                      handleStateCityChange("state", event.target.value)
                    }
                    variant="outlined"
                    required
                  >
                    {states
                      .filter(
                        (s) =>
                          cities.find((c) => c.state === s.name) !== undefined
                      )
                      .map((s) => (
                        <MenuItem key={s.abbreviation} value={s.name}>
                          {s.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container item xs={6} direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="caption">
                    <Box fontWeight="fontWeightBold">City</Box>
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    value={searchData.city}
                    fullWidth
                    select
                    onChange={(event) =>
                      handleStateCityChange("city", event.target.value)
                    }
                    variant="outlined"
                    required
                    disabled={searchData.state === ""}
                  >
                    {cities
                      .filter((c) => c.state === searchData.state)
                      .map((city, i) => (
                        <MenuItem key={i} value={city.city}>
                          {city.city}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          )}
          {searchData.state !== "" && searchData.city !== "" && (
            <Grid item>
              <Autocomplete
                autoComplete
                loading={loadingOrgs}
                loadingText="Fetching organizations"
                noOptionsText="No orgs in this state and city."
                fullWidth
                options={orgOptions}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => handleSelectOrg(newValue)}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "off";
                  return (
                    <TextField
                      {...params}
                      inputProps={inputProps}
                      label="Select Organization"
                      variant="outlined"
                      fullWidth
                      disabled={loadingOrgs}
                    />
                  );
                }}
              />
            </Grid>
          )}
          {selectedOrg !== null && (
            <Grid item>
              <FormControl
                variant="outlined"
                fullWidth
                color="secondary"
                className={classes.formControl}
              >
                <InputLabel id="Role">Role</InputLabel>
                <Select
                  value={roleText}
                  onChange={(event) => handleRoleChange(event)}
                >
                  <MenuItem value={""}>
                    <em>None</em>
                  </MenuItem>
                  {selectedOrg &&
                    selectedOrg.roles.map((role) => (
                      <MenuItem key={role.title} value={role.title}>
                        {role.title}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  Select the role you play in your organization.
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          {selectedOrg !== null &&
            selectedOrg.type === "Multi-Sport Athletics" &&
            roleText !== "" &&
            roleText !== "Admin" && (
              <Multi
                type={selectedOrg.type}
                role={roleText}
                structure={selectedOrg.structure}
                save={handleSaveSeasons}
              />
            )}
          {selectedOrg !== null &&
            selectedOrg.type === "Healthcare" &&
            roleText !== "" &&
            roleText !== "Admin" && (
              <Healthcare
                type={selectedOrg.type}
                role={roleText}
                structure={selectedOrg.structure}
                save={handleSaveHealthcare}
              />
            )}
          {selectedOrg !== null &&
            selectedOrg.type === "Other" &&
            roleText !== "" &&
            roleText !== "Admin" && (
              <Other
                type={selectedOrg.type}
                permission={roleInfo.permission}
                structure={selectedOrg.structure}
                save={handleSaveDepartment}
              />
            )}
          <Grid item>
            <Button
              variant="contained"
              fullWidth
              type="submit"
              className={
                !isOnline ||
                creating ||
                roleText === "" ||
                (selectedOrg !== null &&
                  selectedOrg.type === "Multi-Sport Athletics" &&
                  seasons === null &&
                  roleText !== "Admin") ||
                (selectedOrg !== null &&
                  selectedOrg.type === "Healthcare" &&
                  ((roleText === "Department Head" && department === "") ||
                    (roleText === "Supervisor" && shift === "") ||
                    (roleText === "Staff" &&
                      (department === "" || shift === ""))) &&
                  roleText !== "Admin") ||
                (selectedOrg !== null &&
                  selectedOrg.type === "Other" &&
                  roleText !== "Admin" &&
                  department === "")
                  ? "disabledGrad"
                  : "gradButton"
              }
              style={{ color: "white" }}
              disabled={
                !isOnline ||
                creating ||
                roleText === "" ||
                (selectedOrg !== null &&
                  selectedOrg.type === "Multi-Sport Athletics" &&
                  seasons === null &&
                  roleText !== "Admin") ||
                (selectedOrg !== null &&
                  selectedOrg.type === "Healthcare" &&
                  ((roleText === "Department Head" && department === "") ||
                    (roleText === "Supervisor" && shift === "") ||
                    (roleText === "Staff" &&
                      (department === "" || shift === ""))) &&
                  roleText !== "Admin") ||
                (selectedOrg !== null &&
                  selectedOrg.type === "Other" &&
                  roleText !== "Admin" &&
                  department === "")
              }
            >
              {creating ? (
                <CircularProgress size={25} style={{ color: "#FFFFFF" }} />
              ) : (
                "Finish"
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
);
