import React from "react";
import { NavLink } from "react-router-dom";
import { Grid, Typography, Divider } from "@material-ui/core";

export const WebFooter = React.memo(() => {
  return (
    <Grid container direction="row" justify="center" spacing={2}>
      <Grid item>
        <Typography variant="subtitle1">© 2020 Co-Tracking LLC</Typography>
      </Grid>
      <Grid item>
        <Divider
          orientation="vertical"
          style={{ backgroundColor: "#000000" }}
        />
      </Grid>
      <Grid item>
        <NavLink
          exact
          to="/contact"
          style={{
            textDecoration: "none",
            color: "#17252A",
          }}
          activeStyle={{
            textDecoration: "none",
            color: "#3AAFA9",
          }}
        >
          <Typography variant="subtitle1">Contact Us</Typography>
        </NavLink>
      </Grid>
      <Grid item>
        <NavLink
          exact
          to="/faqs"
          style={{
            textDecoration: "none",
            color: "#17252A",
          }}
          activeStyle={{
            textDecoration: "none",
            color: "#3AAFA9",
          }}
        >
          <Typography variant="subtitle1">FAQs</Typography>
        </NavLink>
      </Grid>
      <Grid item>
        <a
          href="https://pdfhost.io/v/7nIhEdbE0_privacypdf.pdf"
          rel="noopener noreferrer"
          target="_blank"
          style={{
            textDecoration: "none",
            color: "#17252A",
          }}
        >
          <Typography variant="subtitle1">Privacy Policy</Typography>
        </a>
      </Grid>
      <Grid item>
        <a
          href="https://pdfhost.io/v/~iiyrWnES_3f35956b696f498ca8f778d136e4c2c4_enpdf.pdf"
          rel="noopener noreferrer"
          target="_blank"
          style={{
            textDecoration: "none",
            color: "#17252A",
          }}
        >
          <Typography variant="subtitle1">Terms & Conditions</Typography>
        </a>
      </Grid>
    </Grid>
  );
});
