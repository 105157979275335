import React from "react";
import { Grid, Box, useMediaQuery, useTheme } from "@material-ui/core";
import { NavLeft } from "./NavLeft";
import { NavRight } from "./NavRight";
import { useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const Alert = React.memo((props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
});

export const Navbar = React.memo(({ user }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isOnline = useSelector((state) => state.network.isOnline);

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!isOnline}
        key="topcenter"
      >
        <Alert severity="error">You are currently offline.</Alert>
      </Snackbar>
      <Box paddingY={1} paddingX={isMobile ? 2 : 10}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <NavLeft />
          </Grid>
          <Grid item>
            <NavRight />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
});
