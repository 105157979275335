import React, { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import {
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  Grid,
  Button,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import { ShareRounded } from "@material-ui/icons";
import { Clipboard } from "react-feather";
import * as actions from "../../store/actions";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

const ShareModal = React.memo(({ open, close, orgId, copy }) => {
  const link = `${window.location.origin}/join/${orgId}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    copy();
  };

  return (
    <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Invite link</DialogTitle>
      <DialogContent>
        <DialogContentText>{link}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-end"
        >
          <Grid item>
            <Button
              startIcon={<Clipboard />}
              color="secondary"
              variant="contained"
              onClick={handleCopy}
            >
              Copy
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
});

export const Account = React.memo((props) => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const user = auth.user;
  const org = auth.org;
  const [isOpen, setOpen] = useState(false);
  const [isCopyOpen, setIsCopyOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopyOpen = () => {
    setIsCopyOpen(true);
  };

  const handleCopyClose = () => {
    setIsCopyOpen(false);
  };

  const handleCopy = () => {
    handleCopyClose();
    enqueueSnackbar("Copied to clipboard", {
      variant: "success",
    });
  };

  const handleLogout = async () => {
    try {
      await dispatch(actions.signOut());
      history.push("/home");
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <>
      {org && (
        <ShareModal
          open={isCopyOpen}
          close={handleCopyClose}
          orgId={org.id}
          copy={handleCopy}
        />
      )}
      <Grid
        container
        direction="row"
        spacing={3}
        justify="center"
        alignItems="center"
      >
        {org && (
          <Grid
            item
            style={{ borderRight: isMobile ? "" : "2px solid #17252A" }}
            xs={6}
          >
            <Hidden mdUp>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCopyOpen}
                size="small"
              >
                Invite
              </Button>
            </Hidden>
            <Hidden smDown>
              <Button
                startIcon={<ShareRounded />}
                variant="contained"
                color="secondary"
                onClick={handleCopyOpen}
                size="small"
              >
                Invite Users
              </Button>
            </Hidden>
          </Grid>
        )}
        <Grid item xs={org ? 6 : 12}>
          <Box
            display="flex"
            alignItems="center"
            component={ButtonBase}
            onClick={handleOpen}
            ref={ref}
          >
            <Grid
              container
              direction="row"
              spacing={2}
              justify="center"
              alignItems="center"
            >
              <Grid item xs={isMobile ? 12 : 3}>
                <Avatar
                  name={`${user.first_name} ${user.last_name}`}
                  size="40"
                  round={true}
                  color="#2B7A78"
                />
              </Grid>
              <Hidden smDown>
                <Grid item xs={9}>
                  <Typography variant="subtitle1" color="primary">
                    {`${user.first_name} ${user.last_name}`}
                  </Typography>
                </Grid>
              </Hidden>
            </Grid>
          </Box>
          <Menu
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            keepMounted
            PaperProps={{ className: classes.popover }}
            getContentAnchorEl={null}
            anchorEl={ref.current}
            open={isOpen}
          >
            <MenuItem component={RouterLink} to="/dash/account">
              Account
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </>
  );
});
