import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

const firebaseConfig =
  process.env.NODE_ENV !== "production"
    ? {
        apiKey: "AIzaSyAR-7h4xK-Y2vaQ_Havu0IEC45JFUBZ0fk",
        authDomain: "cotrackdev.firebaseapp.com",
        databaseURL: "https://cotrackdev.firebaseio.com",
        projectId: "cotrackdev",
        storageBucket: "cotrackdev.appspot.com",
        messagingSenderId: "602144191249",
        appId: "1:602144191249:web:ad37f9f16166493a81d2b0",
        measurementId: "G-0J828XC67C",
      }
    : {
        apiKey: process.env.REACT_APP_FB_AK,
        authDomain: process.env.REACT_APP_FB_AD,
        databaseURL: process.env.REACT_APP_FB_URL,
        projectId: process.env.REACT_APP_FB_PI,
        storageBucket: process.env.REACT_APP_FB_SB,
        messagingSenderId: process.env.REACT_APP_FB_MSI,
        appId: process.env.REACT_APP_FB_AI,
        measurementId: process.env.REACT_APP_FB_MI,
      };

firebase.initializeApp(firebaseConfig);

export default firebase;
