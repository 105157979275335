import * as actionTypes from "../actions/actionTypes";
import authService from "../../services/authService";

export const orgUpdate = (org) => {
  return {
    type: actionTypes.ORG_UPDATE,
    org: org,
  };
};

export const userUpdate = (user) => {
  return {
    type: actionTypes.USER_UPDATE,
    user: user,
  };
};

export const authSuccess = (storeData) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    storeData: storeData,
  };
};

export const logout = () => {
  localStorage.removeItem("coTrackUID");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export function signOut() {
  return async (dispatch) => {
    try {
      await authService.logout();
      dispatch(logout());
    } catch (error) {
      console.log("signout error: ", error);
    }
  };
}

export function createOrg(successData) {
  return {
    type: actionTypes.CREATE_ORG,
    successData: successData,
  };
}

export function updateUsers(count, action) {
  return {
    type: actionTypes.UPDATE_USERS,
    count: count,
    action: action,
  };
}
