// auth action types
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const ORG_UPDATE = "ORG_UPDATE";
export const USER_UPDATE = "USER_UPDATE";
export const CREATE_ORG = "CREATE_ORG";
export const UPDATE_USERS = "UPDATE_USERS";

// network action types
export const UPDATE_NETWORK_STATUS = "UPDATE_NETWORK_STATUS";
