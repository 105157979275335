import React from "react";
import { useSelector } from "react-redux";
import { Navbar } from "../Navbar/";
import { Footer } from "../Footer/";
import { LandingPage } from "../LandingPage";
import MetaTags from "react-meta-tags";

const Home = React.memo(() => {
  const user = useSelector((state) => state.auth.user);

  return (
    <div>
      <MetaTags>
        <title>Co-Tracking | Home</title>
        <meta name="description" content="Stay ahead of the Virus." />
        <meta property="og:title" content="Home" />
      </MetaTags>
      <Navbar user={user} />
      <LandingPage />
      <Footer />
    </div>
  );
});

export default Home;
