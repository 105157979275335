import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isOnline: navigator.onLine,
};

const updateNetworkStatus = (state, action) => {
  let updateState = { ...state };
  updateState.isOnline = action.isOnline;
  return updateState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_NETWORK_STATUS:
      return updateNetworkStatus(state, action);
    default:
      return state;
  }
};

export default reducer;
