import React from "react";
import { NavLink } from "react-router-dom";
import { Grid, Typography, makeStyles, Box, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
  },
  white: {
    color: "#FFFFFF",
  },
}));

export const TopSection = React.memo(() => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="container">
        <Box paddingY={10}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Typography variant="h1" className={classes.white}>
                <Box fontWeight="fontWeightMedium">
                  Stay ahead of the Virus.
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.white}>
                <Box>
                  Co-Tracking provides a digital way to keep your organization
                  safer. Create up to 15 custom COVID symptom questions and have
                  your users answer them before they come in.
                </Box>
              </Typography>
            </Grid>
            <Grid item>
              <NavLink to="/pricing" style={{ textDecoration: "none" }}>
                <Button variant="contained" color="primary" size="large">
                  14-day free trial
                </Button>
              </NavLink>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
});
