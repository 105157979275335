import { combineReducers } from "redux";
import authReducer from "./auth";
import networkReducer from "./network";

const rootReducer = combineReducers({
  auth: authReducer,
  network: networkReducer,
});

export default rootReducer;
