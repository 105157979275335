import React from "react";
import { NavLink } from "react-router-dom";
import { Grid, Typography, IconButton } from "@material-ui/core";
import { Facebook, Instagram, Twitter } from "@material-ui/icons";

export const MobileFooter = React.memo(() => {
  return (
    <Grid container direction="row" justify="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">© 2020 Co-Tracking LLC</Typography>
      </Grid>
      <Grid item xs={12}>
        <NavLink
          exact
          to="/contact"
          style={{
            textDecoration: "none",
            color: "#17252A",
          }}
          activeStyle={{
            textDecoration: "none",
            color: "#3AAFA9",
          }}
        >
          <Typography variant="subtitle1">Contact Us</Typography>
        </NavLink>
      </Grid>
      <Grid item xs={12}>
        <NavLink
          exact
          to="/faqs"
          style={{
            textDecoration: "none",
            color: "#17252A",
          }}
          activeStyle={{
            textDecoration: "none",
            color: "#3AAFA9",
          }}
        >
          <Typography variant="subtitle1">FAQs</Typography>
        </NavLink>
      </Grid>
      <Grid item xs={12}>
        <a
          href="https://pdfhost.io/v/7nIhEdbE0_privacypdf.pdf"
          rel="noopener noreferrer"
          target="_blank"
          style={{
            textDecoration: "none",
            color: "#17252A",
          }}
        >
          <Typography variant="subtitle1">Privacy Policy</Typography>
        </a>
      </Grid>
      <Grid container item direction="row" justify="flex-start" xs={12}>
        <Grid item>
          <a
            href="https://www.facebook.com/cotrackingapp"
            rel="noopener noreferrer"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "#17252A",
            }}
          >
            <IconButton>
              <Facebook fontSize="small" color="primary" />
            </IconButton>
          </a>
        </Grid>
        <Grid item>
          <a
            href="https://twitter.com/cotrackingapp"
            rel="noopener noreferrer"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "#17252A",
            }}
          >
            <IconButton>
              <Twitter fontSize="small" color="primary" />
            </IconButton>
          </a>
        </Grid>
        <Grid item>
          <a
            href="https://www.instagram.com/cotrackingapp/"
            rel="noopener noreferrer"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "#17252A",
            }}
          >
            <IconButton>
              <Instagram fontSize="small" color="primary" />
            </IconButton>
          </a>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <a
          href="https://pdfhost.io/v/~iiyrWnES_3f35956b696f498ca8f778d136e4c2c4_enpdf.pdf"
          rel="noopener noreferrer"
          target="_blank"
          style={{
            textDecoration: "none",
            color: "#17252A",
          }}
        >
          <Typography variant="subtitle1">Terms & Conditions</Typography>
        </a>
      </Grid>
    </Grid>
  );
});
