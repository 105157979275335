import * as actionTypes from "../actions/actionTypes";

const initialState = {
  user: {
    first_name: "",
    last_name: "",
    email: "",
    createdAt: "",
    latestResponses: {},
    orgs: [],
    orgStructures: {},
    orgOptions: [],
    favOrg: {},
  },
  org: {
    activeUsers: 0,
    address: "",
    city: "",
    createdAt: "",
    customerEmail: "",
    name: "",
    ownerEmail: "",
    ownerId: "",
    roles: [],
    state: "",
    status: "",
    stripeId: "",
    structure: [],
    threshold: 0,
    totalUsers: 1,
    type: "",
    id: "",
  },
};

const authSuccess = (state, action) => {
  let updateState = { ...state };
  updateState.org = action.storeData.org;
  updateState.user = action.storeData.user;
  return updateState;
};

const orgUpdate = (state, action) => {
  let updateState = { ...state };
  updateState.org = action.org;
  return updateState;
};

const userUpdate = (state, action) => {
  let updateState = { ...state };
  updateState.user = action.user;
  return updateState;
};

const authLogout = () => {
  return initialState;
};

const createOrg = (state, action) => {
  let updateState = { ...state };
  updateState.user = action.successData.successUser;
  updateState.org = action.successData.successOrg;
  return updateState;
};

const updateUsers = (state, actions) => {
  let updateState = { ...state };

  switch (actions.action) {
    case "activate":
      updateState.org.activeUsers += actions.count;
      break;
    case "deactivate":
      updateState.org.activeUsers -= actions.count;
      break;
    case "removeActive":
      updateState.org.activeUsers -= actions.count;
      updateState.org.totalUsers -= actions.count;
      break;
    case "removeInactive":
      updateState.org.totalUsers -= actions.count;
      break;

    default:
      break;
  }

  return updateState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout();
    case actionTypes.ORG_UPDATE:
      return orgUpdate(state, action);
    case actionTypes.USER_UPDATE:
      return userUpdate(state, action);
    case actionTypes.CREATE_ORG:
      return createOrg(state, action);
    case actionTypes.UPDATE_USERS:
      return updateUsers(state, action);
    default:
      return state;
  }
};

export default reducer;
