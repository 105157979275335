import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authService from "../services/authService";
import SplashScreen from "./SplashScreen";
import * as actionTypes from "../store/actions";
import { useHistory } from "react-router-dom";

export const AuthAuto = React.memo(({ children }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const initAuth = async () => {
      if (authService.isAuthenticated()) {
        const uid = authService.getUID();

        await authService
          .getUser(uid)
          .then((storeData) => {
            dispatch(actionTypes.authSuccess(storeData));
          })
          .catch((err) => {
            console.log("error getting user: ", err);
            localStorage.clear();
          });
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch, history]);

  if (isLoading) {
    return <SplashScreen />;
  }
  return children;
});
