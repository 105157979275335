import React from "react";
import CoTracking from "../assets/images/CoTrackingTransparent.png";

function Logo(props) {
  return (
    <img alt="Logo" src={CoTracking} {...props} width="200" height="auto" />
  );
}

export default Logo;
