import React from "react";
import { TopSection } from "./TopSection";
import { MidSection } from "./MidSection";
import { BottomSection } from "./BottomSection";
import "./LandingPage.css";

export const LandingPage = React.memo(() => {
  return (
    <div>
      <TopSection />
      <MidSection />
      <BottomSection />
    </div>
  );
});
