import React from "react";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";

import { MobileFooter } from "./MobileFooter";
import { WebFooter } from "./WebFooter";

export const Footer = React.memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box paddingY={1} paddingX={isMobile ? 2 : 10}>
      {isMobile ? <MobileFooter /> : <WebFooter />}
    </Box>
  );
});
