import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import {
  Grid,
  Typography,
  makeStyles,
  Box,
  Tabs,
  Tab,
  IconButton,
  useMediaQuery,
  useTheme,
  Hidden,
  Button,
} from "@material-ui/core";
import {
  ArrowUpwardSharp,
  ArrowDownwardSharp,
  FiberManualRecordOutlined,
  FiberManualRecord,
} from "@material-ui/icons";
import Create from "../../assets/gifs/Create.gif";
import Respond from "../../assets/gifs/Respond.gif";
import Review from "../../assets/gifs/Review.gif";
import Track from "../../assets/gifs/Track.gif";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  white: {
    color: "#FFFFFF",
  },
}));

const tabValues = [
  {
    index: 0,
    image: Create,
    title: "Create Your Questions",
    message:
      "Customize the questions that matter to your organization and get the answers you need from your users.",
  },
  {
    index: 1,
    image: Respond,
    title: "Users Respond Daily",
    message:
      "Once your users log in, they will be prompted to answer the questions for that day. User responses are cleared daily.",
  },
  {
    index: 2,
    image: Review,
    title: "Review Responses",
    message:
      "You and your admins can see user responses in an easy-to-read table to keep track of responses and catch any red flags.",
  },
  {
    index: 3,
    image: Track,
    title: "Track Temperatures",
    message:
      "Our easy-to-read table helps you keep track of daily temperatures. With a quick glance, you can see which users have a temperature above the normal threshold.",
  },
];

const TabPanel = React.memo((props) => {
  const { children, value, index, isMobile, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Grid
            container
            direction={isMobile ? "column" : "row"}
            justify="center"
            spacing={4}
          >
            {isMobile && (
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  className={classes.white}
                  align="center"
                >
                  {props.title}
                </Typography>
              </Grid>
            )}
            <Grid item xs={isMobile ? 12 : 6}>
              <img
                alt={props.title}
                src={props.image}
                className="img-fluid rounded"
              />
            </Grid>
            <Grid
              container
              item
              xs={isMobile ? 12 : 6}
              direction="column"
              justify="center"
              spacing={2}
            >
              {!isMobile && (
                <Grid item>
                  <Typography
                    variant="h4"
                    className={classes.white}
                    align="left"
                  >
                    <Box fontWeight="fontWeightBold">{props.title}</Box>
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Typography
                  variant="h6"
                  className={classes.white}
                  align={isMobile ? "center" : "left"}
                >
                  <Box>{props.message}</Box>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
});

export const BottomSection = React.memo(() => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleUp = () => {
    setValue((old) => {
      if (old === 0) {
        return 3;
      } else {
        return old - 1;
      }
    });
  };
  const handleDown = () => {
    setValue((old) => {
      if (old === 3) {
        return 0;
      } else {
        return old + 1;
      }
    });
  };

  return (
    <div className={classes.root}>
      <div className="container">
        <Box paddingY={10}>
          <Grid container direction="row" justify="center" spacing={2}>
            <Hidden mdDown>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-end"
                item
                xs={3}
              >
                <Grid item>
                  <IconButton onClick={() => handleUp()}>
                    <ArrowUpwardSharp
                      style={{ color: "white" }}
                      fontSize="large"
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    orientation="vertical"
                  >
                    <Tab
                      label={
                        <Typography
                          variant="h4"
                          className={value !== 0 ? classes.white : null}
                        >
                          <Box fontWeight="fontWeightBold">CREATE</Box>
                        </Typography>
                      }
                    />
                    <Tab
                      label={
                        <Typography
                          variant="h4"
                          className={value !== 1 ? classes.white : null}
                        >
                          <Box fontWeight="fontWeightBold">RESPOND</Box>
                        </Typography>
                      }
                    />
                    <Tab
                      label={
                        <Typography
                          variant="h4"
                          className={value !== 2 ? classes.white : null}
                        >
                          <Box fontWeight="fontWeightBold">REVIEW</Box>
                        </Typography>
                      }
                    />
                    <Tab
                      label={
                        <Typography
                          variant="h4"
                          className={value !== 3 ? classes.white : null}
                        >
                          <Box fontWeight="fontWeightBold">TRACK</Box>
                        </Typography>
                      }
                    />
                  </Tabs>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => handleDown()}>
                    <ArrowDownwardSharp
                      style={{ color: "white" }}
                      fontSize="large"
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Hidden>
            <Grid
              container
              item
              xs={isMobile ? 12 : 9}
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <SwipeableViews
                  index={value}
                  onChangeIndex={handleChangeIndex}
                  disableLazyLoading={true}
                  resistance={true}
                >
                  {tabValues.map((val) => {
                    return (
                      <TabPanel
                        key={val.index}
                        value={value}
                        index={val.index}
                        title={val.title}
                        message={val.message}
                        image={val.image}
                        isMobile={isMobile}
                      />
                    );
                  })}
                </SwipeableViews>
              </Grid>
              <Hidden lgUp>
                <Grid item>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                  >
                    <Tab
                      icon={
                        value === 0 ? (
                          <FiberManualRecord
                            style={{
                              color: theme.palette.secondary.main,
                              margin: "auto",
                            }}
                          />
                        ) : (
                          <FiberManualRecordOutlined
                            style={{ color: "white", margin: "auto" }}
                          />
                        )
                      }
                    />
                    <Tab
                      icon={
                        value === 1 ? (
                          <FiberManualRecord
                            style={{
                              color: theme.palette.secondary.main,
                              margin: "auto",
                            }}
                          />
                        ) : (
                          <FiberManualRecordOutlined
                            style={{ color: "white", margin: "auto" }}
                          />
                        )
                      }
                    />
                    <Tab
                      icon={
                        value === 2 ? (
                          <FiberManualRecord
                            style={{
                              color: theme.palette.secondary.main,
                              margin: "auto",
                            }}
                          />
                        ) : (
                          <FiberManualRecordOutlined
                            style={{ color: "white", margin: "auto" }}
                          />
                        )
                      }
                    />
                    <Tab
                      icon={
                        value === 3 ? (
                          <FiberManualRecord
                            style={{
                              color: theme.palette.secondary.main,
                              margin: "auto",
                            }}
                          />
                        ) : (
                          <FiberManualRecordOutlined
                            style={{ color: "white", margin: "auto" }}
                          />
                        )
                      }
                    />
                  </Tabs>
                </Grid>
              </Hidden>
            </Grid>
            <Grid item>
              <NavLink to="/demos" style={{ textDecoration: "none" }}>
                <Button variant="contained" color="secondary" size="large">
                  See them in action
                </Button>
              </NavLink>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
});
