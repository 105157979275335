import React, { useState } from "react";
import {
  Grid,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";

import { SaveRounded, EditRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  formControl: {
    paddingTop: theme.spacing(1),
    minWidth: 120,
  },
}));

const getAllShifts = (structure) => {
  let shiftSet = new Set();
  structure.forEach((s) => {
    shiftSet.add(s.shift);
  });
  return [...shiftSet];
};

const getAllDepartments = (structure) => {
  let departmentSet = new Set();
  structure.forEach((s) => {
    s.departments.forEach((d) => {
      departmentSet.add(d);
    });
  });
  return [...departmentSet];
};

const getDepartmentsForShift = (structure, shift) => {
  const findShift = structure.find((s) => s.shift === shift);
  return findShift.departments;
};

const roleSentence = (type, role, permission) => {
  let sentence = "";

  if (type === "Multi-Sport Athletics") {
    switch (role) {
      case "Coach":
        sentence =
          "Select the sport that you coach in each season. Leave blank if you don't coach during that season";
        break;
      case "Player":
        sentence =
          "Select the sport that you play in each season. Leave blank if you don't play during that season";
        break;
      default:
        break;
    }
  }

  if (type === "Healthcare") {
    switch (role) {
      case "Department Head":
        sentence = "Select the department that you are a head of.";
        break;
      case "Supervisor":
        sentence = "Select the shift that you supervise.";
        break;
      case "Staff":
        sentence = "Select the shift and department that you work in.";
        break;
      default:
        break;
    }
  }

  if (type === "Other") {
    switch (permission) {
      case "Manager":
        sentence = "Select the department that you oversee.";
        break;
      case "Staff":
        sentence = "Select the department that you are a part of.";
        break;
      default:
        break;
    }
  }

  return sentence;
};

export const Multi = React.memo(({ type, role, structure, save }) => {
  const classes = useStyles();
  const [seasons, setSeasons] = useState({
    fall: "",
    winter: "",
    spring: "",
  });
  const [locked, setLocked] = useState(false);

  // get options for seasons from structure
  const fall = structure.find((s) => s.season === "fall");
  const winter = structure.find((s) => s.season === "winter");
  const spring = structure.find((s) => s.season === "spring");

  const handleChangeMultiple = (event, type) => {
    setSeasons((old) => {
      let update = { ...old };
      update[type] = event.target.value;
      return update;
    });
  };

  const lock = () => {
    setLocked(true);
    save(seasons);
  };

  const unlock = () => {
    setLocked(false);
    save(null);
  };

  return (
    <Grid container item direction="column" spacing={2}>
      <Grid item>
        <Typography variant="subtitle1">
          {roleSentence(type, role, null)}
        </Typography>
      </Grid>
      {(!locked || seasons.fall !== "") && (
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            fullWidth
            color="secondary"
            className={classes.formControl}
            disabled={locked}
          >
            <InputLabel id="fall">
              {role === "Coach"
                ? "Sport you coach in the fall"
                : "Sport you play in the fall"}
            </InputLabel>
            <Select
              value={seasons.fall}
              onChange={(event) => handleChangeMultiple(event, "fall")}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {fall.sports.map((sport, i) => (
                <MenuItem key={i} value={sport}>
                  {sport}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {(!locked || seasons.winter !== "") && (
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            fullWidth
            color="secondary"
            className={classes.formControl}
            disabled={locked}
          >
            <InputLabel id="winter">
              {role === "Coach"
                ? "Sport you coach in the winter"
                : "Sport you play in the winter"}
            </InputLabel>
            <Select
              value={seasons.winter}
              onChange={(event) => handleChangeMultiple(event, "winter")}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {winter.sports.map((sport, i) => (
                <MenuItem key={i} value={sport}>
                  {sport}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {(!locked || seasons.spring !== "") && (
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            fullWidth
            color="secondary"
            className={classes.formControl}
            disabled={locked}
          >
            <InputLabel id="spring">
              {role === "Coach"
                ? "Sport you coach in the spring"
                : "Sport you play in the spring"}
            </InputLabel>
            <Select
              value={seasons.spring}
              onChange={(event) => handleChangeMultiple(event, "spring")}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {spring.sports.map((sport, i) => (
                <MenuItem key={i} value={sport}>
                  {sport}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid
        container
        item
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <Grid item>
          {locked ? (
            <Button
              startIcon={<EditRounded />}
              variant="contained"
              className="gradButton"
              style={{ color: "white" }}
              onClick={() => unlock()}
            >
              Edit sports
            </Button>
          ) : (
            <Button
              startIcon={<SaveRounded />}
              variant="contained"
              className={
                seasons.fall === "" &&
                seasons.winter === "" &&
                seasons.winter === ""
                  ? "disabledGrad"
                  : "gradButton"
              }
              style={{ color: "white" }}
              onClick={() => lock()}
              disabled={
                seasons.fall === "" &&
                seasons.winter === "" &&
                seasons.winter === ""
              }
            >
              Save sports
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});

export const Healthcare = React.memo(({ type, role, structure, save }) => {
  const classes = useStyles();
  const [department, setDepartment] = useState("");
  const [shift, setShift] = useState("");
  const [locked, setLocked] = useState(false);

  // get shift & department options
  const shiftOptions = getAllShifts(structure);
  const departmentOptions = getAllDepartments(structure);

  const handleDepartmentChange = (event) => {
    setDepartment(event.target.value);
  };

  const handleShiftChange = (event) => {
    setShift(event.target.value);
  };

  const lock = () => {
    const struct = {
      department: department,
      shift: shift,
    };
    setLocked(true);
    save(struct);
  };

  const unlock = () => {
    const struct = {
      department: "",
      shift: "",
    };
    setLocked(false);
    save(struct);
  };

  return (
    <Grid container item direction="column" spacing={2}>
      <Grid item>
        <Typography variant="subtitle1">
          {roleSentence(type, role, null)}
        </Typography>
      </Grid>
      {role === "Department Head" && (
        <Grid item>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              color="secondary"
              className={classes.formControl}
              disabled={locked}
            >
              <InputLabel id="department">Department</InputLabel>
              <Select
                value={department}
                onChange={(event) => handleDepartmentChange(event)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {departmentOptions.map((d, i) => (
                  <MenuItem key={i} value={d}>
                    {d}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      {role !== "Department Head" && (
        <Grid item>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              color="secondary"
              className={classes.formControl}
              disabled={locked}
            >
              <InputLabel id="shift">Shift</InputLabel>
              <Select
                value={shift}
                onChange={(event) => handleShiftChange(event)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {shiftOptions.map((s, i) => (
                  <MenuItem key={i} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      {role === "Staff" && shift !== "" && (
        <Grid item>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              color="secondary"
              className={classes.formControl}
              disabled={locked}
            >
              <InputLabel id="department">Department</InputLabel>
              <Select
                value={department}
                onChange={(event) => handleDepartmentChange(event)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {getDepartmentsForShift(structure, shift).map((d, i) => (
                  <MenuItem key={i} value={d}>
                    {d}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        item
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <Grid item>
          {locked ? (
            <Button
              startIcon={<EditRounded />}
              variant="contained"
              className="gradButton"
              style={{ color: "white" }}
              onClick={() => unlock()}
            >
              Edit
            </Button>
          ) : (
            <Button
              startIcon={<SaveRounded />}
              variant="contained"
              className={
                (role === "Department Head" && department === "") ||
                (role === "Supervisor" && shift === "") ||
                (role === "Staff" && (department === "" || shift === ""))
                  ? "disabledGrad"
                  : "gradButton"
              }
              style={{ color: "white" }}
              onClick={() => lock()}
              disabled={
                (role === "Department Head" && department === "") ||
                (role === "Supervisor" && shift === "") ||
                (role === "Staff" && (department === "" || shift === ""))
              }
            >
              Save
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});

export const Other = React.memo(
  ({ type, role, permission, structure, save }) => {
    const classes = useStyles();
    const [department, setDepartment] = useState("");
    const [locked, setLocked] = useState(false);

    const handleDepartmentChange = (event) => {
      setDepartment(event.target.value);
    };

    const lock = () => {
      setLocked(true);
      save(department);
    };

    const unlock = () => {
      setLocked(false);
      save("");
    };

    return (
      <Grid container item direction="column" spacing={2}>
        <Grid item>
          <Typography variant="subtitle1">
            {roleSentence(type, null, permission)}
          </Typography>
        </Grid>
        <Grid item>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              color="secondary"
              className={classes.formControl}
              disabled={locked}
            >
              <InputLabel id="department">Department name</InputLabel>
              <Select
                value={department}
                onChange={(event) => handleDepartmentChange(event)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {structure.map((d, i) => (
                  <MenuItem key={i} value={d}>
                    {d}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item>
            {locked ? (
              <Button
                startIcon={<EditRounded />}
                variant="contained"
                className="gradButton"
                style={{ color: "white" }}
                onClick={() => unlock()}
              >
                Edit
              </Button>
            ) : (
              <Button
                startIcon={<SaveRounded />}
                variant="contained"
                className={department === "" ? "disabledGrad" : "gradButton"}
                style={{ color: "white" }}
                onClick={() => lock()}
                disabled={department === ""}
              >
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
