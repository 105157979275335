import React, { useEffect, useState, useRef } from "react";
import { useLocation, matchPath } from "react-router";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import Avatar from "react-avatar";
import firebase from "../../shared/firebase";
import { useSnackbar } from "notistack";
import * as actions from "../../store/actions";

import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles,
  Button,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Menu,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Snackbar,
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";

import {
  HelpOutlineRounded,
  QuestionAnswerRounded,
  AssignmentRounded,
  AddCircleRounded,
  ExpandMoreRounded,
  BusinessRounded,
  FavoriteBorderRounded,
  FavoriteRounded,
  AddCircleOutlineRounded,
  GroupRounded,
  AddAlertRounded,
} from "@material-ui/icons";
import { DollarSign as Subscription } from "react-feather";
import Logo from "../../components/Logo";
import NavItem from "./NavItem";
import { SelectOrg } from "../../components/SelectOrg";
import {
  incrementActiveAndTotalUsers,
  incrementTotalUsers,
} from "../../shared/fbFunctions";

const db = firebase.firestore();

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const getCurrentOrg = (structures, orgId) => {
  let org = structures[orgId];
  org.id = orgId;
  return org;
};

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  popover: {
    width: 200,
  },
}));

const Alert = React.memo((props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
});

const NavDialog = React.memo(
  ({ onClose, menuClose, open, user, handleFavOrg, isOnline }) => {
    const dispatch = useDispatch();
    const [creating, setCreating] = useState(false);
    const [createError, setCreateError] = useState("");
    const [fav, setFav] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const handleCheck = (event) => {
      setFav(event.target.checked);
    };

    const handleClose = () => {
      onClose();
    };

    // handle adding org and structure to user
    const handleOrgAndStructure = (org, structure) => {
      setCreating(true);
      setCreateError("");
      let updateStructures = { ...user.orgStructures };

      // update structure accordingly
      structure.favorite = fav;

      // if new org is set to fav, set fav as false for other orgs
      if (fav) {
        for (let orgId of Object.keys(updateStructures)) {
          updateStructures[orgId].favorite = false;
        }
      }

      updateStructures[structure.id] = structure;

      let updateUser = { ...user };
      updateUser.orgs.push(org.id);
      updateUser.orgStructures = updateStructures;
      updateUser.orgOptions.push(org);

      // update user doc in fb
      db.collection("users")
        .doc(user.uid)
        .update({
          orgStructures: updateStructures,
          orgs: firebase.firestore.FieldValue.arrayUnion(org.id),
        })
        .then(() => {
          // dispatch user and org data to store
          if (fav) {
            updateUser.favOrg = org;
            handleFavOrg(org);
          }
          dispatch(actions.userUpdate(updateUser));
          dispatch(actions.orgUpdate(org));
          // update business count
          if (structure.status === "Active") {
            incrementActiveAndTotalUsers(structure.id);
          } else {
            incrementTotalUsers(structure.id);
          }
          setCreating(false);
          handleClose();
          menuClose();
          history.push("/");

          enqueueSnackbar("Organization joined", {
            variant: "success",
          });
        })
        .catch((error) => {
          console.log("error: ", error);
          setCreating(false);
          setCreateError(error.message);
        });
    };

    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle id="form-dialog-title">Join a new org</DialogTitle>
        <DialogContent>
          <Box py={1}>
            <SelectOrg
              create={handleOrgAndStructure}
              creating={creating}
              error={createError}
              orgArray={user.orgs}
              isOnline={isOnline}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={fav}
                    onChange={handleCheck}
                    name="set fav"
                    color="secondary"
                  />
                }
                label="Set as favorite org"
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
);

const NavBar = React.memo(({ openMobile, onMobileClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const selectedOrg = auth.org;
  const user = auth.user;
  const isOnline = useSelector((state) => state.network.isOnline);
  const [favOrg, setFavOrg] = useState(auth.user.favOrg);
  const ref = useRef(null);
  const [modOpen, setModOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const currentOrg =
    user.orgStructures && selectedOrg
      ? getCurrentOrg(user.orgStructures, selectedOrg.id)
      : null;

  const handleModOpen = () => {
    setModOpen(true);
  };

  const handleModClose = () => {
    setModOpen(false);
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const showSuccessMessage = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
    });
  };

  const handleFavOrg = (org) => {
    setFavOrg(org);
  };

  // set org as favorite
  const favorite = (orgId) => {
    // get user existing structure
    const updateStructure = { ...user.orgStructures };
    let newFavOrg;

    // loop through setting new org as favorite
    for (let oId of Object.keys(updateStructure)) {
      if (oId === favOrg.id) {
        updateStructure[oId].favorite = false;
      }

      if (oId === orgId) {
        updateStructure[oId].favorite = true;
        newFavOrg = updateStructure[oId];
        newFavOrg.id = oId;
      }
    }

    // make actual update in fb
    db.collection("users")
      .doc(user.uid)
      .update({
        orgStructures: updateStructure,
      })
      .then(() => {
        handleFavOrg(newFavOrg);
        showSuccessMessage("Successfully set as favorite");
      })
      .catch((err) => {
        console.log("error setting as favorite: ", err.message);
      });
  };

  // switch current org
  const switchOrg = (org) => {
    // dispatch action
    dispatch(actions.orgUpdate(org));
    history.push("/");

    // close menu
    handleMenuClose();
  };

  // navigation for owner user
  const ownerNav = [
    {
      subheader: "Organization",
      items: [
        {
          title: "Internal Responses",
          icon: AssignmentRounded,
          href: "/dash/responses",
        },
        {
          title: "Visitor Responses",
          icon: AddAlertRounded,
          href: "/dash/external",
        },
        {
          title: "Manage Users",
          icon: GroupRounded,
          href: "/dash/users",
        },
        {
          title: "Org Details",
          icon: BusinessRounded,
          href: "/dash/details",
        },
      ],
    },
    {
      subheader: "Check In",
      items: [
        {
          title: "Create Questions",
          icon: HelpOutlineRounded,
          href: "/dash/questions",
        },
        {
          title: "Answer Questions",
          icon: QuestionAnswerRounded,
          href: "/dash/answer",
        },
      ],
    },
    {
      subheader: "Subscription",
      items: [
        {
          title: "Manage Subscription",
          icon: Subscription,
          href: "/dash/subscription",
        },
      ],
    },
  ];

  // navigation for admin/non lowest level user
  const adminNav = [
    {
      subheader: "Organization",
      items: [
        {
          title: "Internal Responses",
          icon: AssignmentRounded,
          href: "/dash/responses",
        },
        {
          title: "Visitor Responses",
          icon: AddAlertRounded,
          href: "/dash/external",
        },
      ],
    },
    {
      subheader: "Check In",
      items: [
        {
          title: "Answer Questions",
          icon: QuestionAnswerRounded,
          href: "/dash/answer",
        },
      ],
    },
  ];

  // navigation for mid level user
  const midNav = [
    {
      subheader: "Organization",
      items: [
        {
          title: "Responses",
          icon: AssignmentRounded,
          href: "/dash/responses",
        },
      ],
    },
    {
      subheader: "Check In",
      items: [
        {
          title: "Answer Questions",
          icon: QuestionAnswerRounded,
          href: "/dash/answer",
        },
      ],
    },
  ];

  // navigation for lowest level user
  const lowestNav = [
    {
      subheader: "Check In",
      items: [
        {
          title: "Answer Questions",
          icon: QuestionAnswerRounded,
          href: "/dash/answer",
        },
      ],
    },
  ];

  // nav if user doesn't belong to any org
  const orglessNav = [
    {
      subheader: "Organization",
      items: [
        {
          title: "Add Organization",
          icon: AddCircleRounded,
          href: "/dash/addorg",
        },
      ],
    },
  ];

  // nav variable
  let navConfig;

  // if user isn't part of an org
  if (!("orgs" in user) || user.orgs.length === 0) {
    navConfig = orglessNav;
  }

  // if user is owner of current org
  if (currentOrg && currentOrg.title === "Owner") {
    navConfig = ownerNav;
  }

  // if selected org is sport
  if (
    currentOrg &&
    currentOrg.title !== "Owner" &&
    (currentOrg.type === "Single-Sport Athletics" ||
      currentOrg.type === "Multi-Sport Athletics")
  ) {
    switch (currentOrg.title) {
      case "Admin":
        navConfig = adminNav;
        break;
      case "Coach":
        navConfig = midNav;
        break;
      case "Player":
        navConfig = lowestNav;
        break;
      default:
        break;
    }
  }

  // if selected org is healthcare
  if (
    currentOrg &&
    currentOrg.title !== "Owner" &&
    currentOrg.type === "Healthcare"
  ) {
    switch (currentOrg.title) {
      case "Admin":
        navConfig = adminNav;
        break;
      case "Department Head":
        navConfig = midNav;
        break;
      case "Supervisor":
        navConfig = midNav;
        break;
      case "Staff":
        navConfig = lowestNav;
        break;
      default:
        break;
    }
  }

  // if selected org is other
  if (
    currentOrg &&
    currentOrg.title !== "Owner" &&
    currentOrg.type === "Other"
  ) {
    switch (currentOrg.permission) {
      case "Admin":
        navConfig = adminNav;
        break;
      case "Manager":
        navConfig = midNav;
        break;
      case "Staff":
        navConfig = lowestNav;
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/home">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/dash/account">
              <Avatar
                name={`${user.first_name} ${user.last_name}`}
                size="60"
                round={true}
                color="#2B7A78"
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/dash/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.first_name} ${user.last_name}`}
            </Link>
            {selectedOrg && selectedOrg.name !== "" && (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Box
                    display="flex"
                    alignItems="center"
                    onClick={handleMenuOpen}
                    ref={ref}
                  >
                    <Button
                      variant="text"
                      color="secondary"
                      size="small"
                      endIcon={<ExpandMoreRounded />}
                      onClick={handleMenuOpen}
                    >
                      {selectedOrg.name}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
        <Menu
          onClose={handleMenuClose}
          keepMounted
          PaperProps={{ className: classes.popover }}
          getContentAnchorEl={null}
          anchorEl={ref.current}
          open={menuOpen}
        >
          <Box mx={2}>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="subtitle1" align="center">
                  Switch Organization
                </Typography>
              </Grid>
              {"orgOptions" in user &&
                user.orgOptions.map((org) => (
                  <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    key={org.id}
                  >
                    <Grid
                      container
                      item
                      xs={9}
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                    >
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          startIcon={<BusinessRounded color="secondary" />}
                          disabled={org.id === selectedOrg.id}
                          variant="text"
                          onClick={() => switchOrg(org)}
                        >
                          <Typography noWrap variant="subtitle2">
                            {org.name}
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton
                        disabled={org.id === favOrg.id}
                        onClick={() => favorite(org.id)}
                      >
                        {org.id === favOrg.id ? (
                          <FavoriteRounded color="secondary" />
                        ) : (
                          <Tooltip title="Favorite Org">
                            <FavoriteBorderRounded color="secondary" />
                          </Tooltip>
                        )}
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              {"orgs" in user && user.orgs.length < 10 && (
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    startIcon={<AddCircleOutlineRounded color="secondary" />}
                    variant="contained"
                    color="primary"
                    onClick={handleModOpen}
                  >
                    Join new org
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Menu>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius="borderRadius">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              component={RouterLink}
              to="/contact"
              fullWidth
            >
              Contact Us
            </Button>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <NavDialog
        open={modOpen}
        onClose={handleModClose}
        menuClose={handleMenuClose}
        orgOptions={user.orgOptions}
        user={user}
        handleFavOrg={handleFavOrg}
        isOnline={isOnline}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!isOnline}
        key="topcenter"
      >
        <Alert severity="error">You are currently offline.</Alert>
      </Snackbar>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
});

export default NavBar;
