import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { withRouter, BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import MomentUtils from "@date-io/moment";
import { createBrowserHistory } from "history";
import { createStyles, makeStyles, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import useSettings from "./hooks/useSettings";
import { createTheme } from "./theme";

import Layout from "./hoc/Layout/Layout";
import { AuthAuto } from "./components/AuthAuto";

import { Routes } from "./Routes";

const history = createBrowserHistory();

// stripe promise
const promise =
  process.env.NODE_ENV !== "production"
    ? loadStripe(
        "pk_test_51HCYFRGpNedNsmYPdRZttCH4pW6ABDIuS84aX6dUhBY7ZPZVJlM6cHaOH8rgxuyp1HMnvwTwBiSS5nHGU8voXbb100ECjNhggv"
      )
    : loadStripe(
        "pk_live_51HCYFRGpNedNsmYPWtHUPb3ajLGsxtKk6vTo659aaxybiG8tmy9M7jTqnzFovFH0RakfFMVumvn9TBMIz4LAtghb005QgWjBSi"
      );

const useStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
    },
  })
);

const App = () => {
  useStyles();
  const { settings } = useSettings();
  return (
    <div style={{ height: "100%" }}>
      <ThemeProvider theme={createTheme(settings)}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={1}>
            <BrowserRouter history={history}>
              <Elements stripe={promise}>
                <Layout>
                  <AuthAuto>
                    <Routes />
                  </AuthAuto>
                </Layout>
              </Elements>
            </BrowserRouter>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
};

export default withRouter(App);
