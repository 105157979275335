import firebase from "./firebase";

const db = firebase.firestore();

export const incrementActiveAndTotalUsers = async (orgId) => {
  return db
    .collection("orgs")
    .doc(orgId)
    .update({
      activeUsers: firebase.firestore.FieldValue.increment(1),
      totalUsers: firebase.firestore.FieldValue.increment(1),
    });
};

export const incrementTotalUsers = async (orgId) => {
  return db
    .collection("orgs")
    .doc(orgId)
    .update({
      totalUsers: firebase.firestore.FieldValue.increment(1),
    });
};
